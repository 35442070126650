import { Checkbox } from "antd"
export const POSITIONLESS_SPORTS = ['pga']

export const POSITIONLESS_LU_HEADING = {
  'pga': {
    'dk': 'G',
    'fd': 'G'
  },
  'mma': {
    'dk': 'F'
  },
  'nascar': {
    'dk': 'D'
  }
}

export const GET_POSITIONLESS_ROSTER_POSITIONS = (luSize, sport, site) => {
  const headingPrefix = POSITIONLESS_LU_HEADING[sport][site]

  let rosterPos = []
  for (let i=0; i<luSize; i++) {
    rosterPos.push(`${headingPrefix}${i + 1}`)
  }

  return rosterPos
}

export const GET_POSITIONLESS_COLS = (luSize, sport, site, slate, showSaved, counter, season, checkedLineups, checkAll, checkLineup) => {
  console.log(checkedLineups)
  
  const rosterPositions = GET_POSITIONLESS_ROSTER_POSITIONS(luSize, sport, site)
  let cols = [
    {
      title: '',
      width: '50px',
      title: <Checkbox defaultChecked={false} onChange={(e) => {checkAll(e.target.checked)}}/>,
      width: '40px',
      render: (_, row) => {
        let isChecked = false
        if (checkedLineups.indexOf(row.rawTable.lineupId) >= 0) {
          isChecked = true
        }
        return (
          <Checkbox checked={isChecked} onClick={() => {checkLineup(row.rawTable.lineupId, !isChecked)}}/>
        )
      }
    }
      
  ]
  
  for (let i=0; i<luSize; i++) {
    const pos = rosterPositions[i]
    cols.push(
      {
        title: pos,
        dataIndex: pos,
        key: pos,
        render: (player) => {return player.Name}
      },
    )
  }

  return cols
}